import React from "react";
import { SuperTag } from "~components";
import Widget from "./style";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import FluidImage from "../../../../components/FluidImage";
import SbEditable from "storyblok-react";

export default function ServiceWidget({ blok, ...rest }) {
  return (
    <Widget {...rest}>
      <Widget.Icon>
        {blok.image && blok.image.filename && (
          <FluidImage blok={blok} image={blok.image} />
        )}
      </Widget.Icon>
      <Widget.Body>
        <SbEditable content={blok}>
          <Widget.Title>{blok.title}</Widget.Title>
        </SbEditable>
        <Widget.Text>
          <SbEditable content={blok}>
            <SuperTag value={blok.text} />
          </SbEditable>
        </Widget.Text>
      </Widget.Body>
    </Widget>
  );
}
